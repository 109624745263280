import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DESC } from '@app/core/constants/Constants';
import RoleEnum from '@app/core/enums/role.enum';
import UserStatusEnum from '@app/core/enums/user-status.enum';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { Page } from '@app/core/models/input/page/pages.model';
import { User } from '@app/core/models/input/user.model';
import { UserService } from '@app/core/services/user/user.service';
import { FilterUtils } from '@app/core/utils/filter.utils';
import { faCaretDown, faCaretLeft, faCaretRight, faCaretUp, faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list-doctors',
  templateUrl: './list-doctors.component.html',
  styleUrls: ['./list-doctors.component.scss']
})
export class ListDoctorsComponent implements OnInit {
  // fontawesome
  faFileCirclePlus = faFileCirclePlus;
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;

  // enum
  userStatusEnum = UserStatusEnum;

  // Utils
  filterUtils: FilterUtils;

  users?: User[];
  page?: Page;
  pagination = new Pagination();
  filters = new Array<Filter>();
  sorters = new Sorters('creationDate', DESC);


  constructor(
    private userService: UserService, 
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.filterUtils = new FilterUtils(this.filters);
  }


  ngOnInit() {
    this.addFilter(
      'rolesId',
      RoleEnum.COMPANY_DOCTOR.toString()
    );
  }

  addFilter(name: string, filter: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.addFilter(name, filter);
    this.getDoctorsByCompany();
  }

  removeFilter(name: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.removeFilter(name);
    this.getDoctorsByCompany();
  }

  paginar(amount: number) {
    this.pagination.page += amount;
    this.getDoctorsByCompany();
  }


  getDoctorsByCompany() {
    this.userService
      .getUsers(this.pagination, this.filters, this.sorters)
      .subscribe({
        next: usersPage => {
          this.page = usersPage;
          this.users = this.page.content;

        },
      });
  }


  checkIfCompanyCanAddDoctors() {
    this.userService.getIfCompanyCanAddNewDoctor().subscribe ({
      next: response => {
        if (response.status === 200) {
          this.router.navigate(['new'], { relativeTo: this.route });             
        } 
      }
    })
  }

}
